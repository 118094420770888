import React from 'react'
import {useEffect,useState, useRef} from 'react';
import {returnNext, useEvent, animateDivScroll} from './functions.js'
//простой слайдер для показа div блоков использует overflow scroll
//version 15.01.25
//параметры
//arr=Array - массив со слайдами
//autoplay=Int|false - нужна ли автопрокрутка
//showpoints=true|false - показывать ли точки со слайдером
//afterPoints=<React child> - показать блок после точек, при условии, что точки отображаются
function SliderOverflow(props)
{
let timeOut;
const [n,setN]=useState(0);
const [stopTimer,setStopTimer]=useState(false)
const stopRef=useRef(null)
stopRef.current=stopTimer
const nRef=useRef(null)
nRef.current=n
const arr=props.arr
const scrollableDivRef = useRef(null);
const [isDragging, setIsDragging] = useState(false);
const [startX, setStartX] = useState(0);
const [curPosition,setCurPosition]=useState({left:1,width:1})
const [visiblePoints, setVisiblePoints] = useState(false);
const goTo=(num)=>
{
const item=scrollableDivRef.current.childNodes[0].childNodes[num]
  if (typeof item!=='undefined') 
  {
animateDivScroll(scrollableDivRef.current, item.offsetLeft, 300)
//window.setTimeout(()=>setN(num),350)
window.clearTimeout(timeOut)
timeOut=window.setTimeout(()=>setN(num),400)
  }
}
//generate points
const points=<div className="slider_points">{arr.map((item,i)=><div key={i} className={(i===n)?"slider_point slider_point_active":"slider_point"} onClick={()=>{setStopTimer(true);goTo(i);}} />)}</div>

  const handleMouseDown = e => {
  if ('PointerEvents' in window) return true
    setIsDragging(true);
    setStartX(e.clientX);
setStopTimer(true)
  };

  const handleMouseUp = () => {
  if ('PointerEvents' in window) return true
    setIsDragging(false);
  };

  const handleMouseMove = e => {
  if ('PointerEvents' in window) return true
    if (!isDragging) return;
    const deltaX = e.clientX - startX;
scrollableDivRef.current.scrollTo({ left: scrollableDivRef.current.scrollLeft + -deltaX, behavior: "smooth" });
//animateDivScroll(scrollableDivRef.current, scrollableDivRef.current.scrollLeft + -deltaX, 300)
  };
const i_resize=(e)=>
{
window.setTimeout(function(){
	if (scrollableDivRef.current!==null)
setVisiblePoints(window.innerWidth<scrollableDivRef.current.lastChild.offsetWidth)
},10)
}
//add handler for resize
useEvent('resize', i_resize)
useEvent('load', i_resize)
useEffect(() => {
	if ( !(typeof props.autoplay==='undefined' || props.autoplay===null || stopRef.current===true) ) return;
window.clearTimeout(timeOut)
	if (scrollableDivRef.current===null) return false
const allArr=scrollableDivRef.current.childNodes[0].childNodes
  if (curPosition.left+window.innerWidth>=curPosition.width)
    {
setN(allArr.length-1)
    }
    else   if (curPosition.left===0) 
     {
setN(0)
     }
   else for (var i=0; i<allArr.length; i++)
   {
    if (parseInt(allArr[i].offsetLeft)>parseInt(curPosition.left-1))
      {
setN(i);
break
     }
   }
/*const item=(curPosition.left/curPosition.width)*scrollableDivRef.current.lastChild.childElementCount
setN(Math.floor(item))*/
//console.log(item)
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [curPosition]);

useEffect(() => {
setN(0)
let interval=window.setInterval(()=>
	{
if (typeof props.autoplay==='undefined' || props.autoplay===null || stopRef.current===true) return;
const next=returnNext(false,nRef.current,arr.length)
//setN(next)
goTo(next)
	
	},props.autoplay)
  return () => {
    clearInterval(interval);
  };// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
return(
<div>
	<div className={(visiblePoints)?"slider_top_wrapper cursor_move":"slider_top_wrapper"} ref={scrollableDivRef} onTouchStart={(e)=>setStopTimer(true)} onScroll={(e)=>{if (typeof e.target!=='undefined') setCurPosition({left:e.target.scrollLeft,width:e.target.lastChild.offsetWidth})}}  onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} /*style={{touchAction:'pan-x'}}*/>
		<div className="slider_wrapper">{arr.map((it,i)=><div key={i} className="slider_wrapper_one">{it}</div>)}</div>
	</div>
{(props.showpoints!==false && visiblePoints)?points:''}
{(props.showpoints!==false && visiblePoints)?props.afterPoints:''}
</div>
)
}

export default SliderOverflow;