import React from 'react'
import './new_year_menu.scss'
function NewYearMenu()
{
return(
<div>
<div className="ny_circle ny_c0"></div>
<div className="ny_circle ny_c1"></div>
<div className="ny_circle ny_c2"></div>
<div className="ny_circle ny_c3"></div>
<div className="ny_circle ny_c4"></div>
<div className="ny_circle ny_c5"></div>
<div className="ny_circle ny_c6"></div>
<div className="ny_circle ny_c7"></div>
</div>
)
}
export default NewYearMenu;